import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './utils/guards/auth.guard';
import {RoleGuard} from './utils/guards/role.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/account/login' },
  { path: 'account', loadChildren: () => import('./children/account/account.module').then(m => m.AccountModule) },
  { path: 'app', loadChildren: () => import('./children/main/main.module').then(m => m.MainModule), canActivate: [AuthGuard]},
];
// @ts-ignore
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
