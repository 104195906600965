import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-new-email',
  templateUrl: './new-email.component.html',
  styleUrls: ['./new-email.component.scss']
})
export class NewEmailComponent implements OnInit {

  public newEmailForm: FormGroup;

  public submittedForm: boolean;

  constructor(private fb: FormBuilder) {
    this.newEmailForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    this.submittedForm = false;
  }

  getForm() {
    return this.newEmailForm.controls;
  }

  public validateData() {
    this.submittedForm = true;
  }
}
