import {Injectable} from '@angular/core';
import {PersistService} from './persist.service';
import {HttpWrapper} from './http-wrapper.service';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AlertService} from './alert.service';
import {SpinnerService} from './spinner.service';


const jwt = new JwtHelperService();

// @ts-ignore
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private  persist: PersistService,
              private http: HttpWrapper,
              private router: Router,
              private alert: AlertService,
              private spinner: SpinnerService) {
  }

  public login(email: any, password: any, remember: any) {
    this.persist.remove('remember');
    this.persist.removeTemp('token');
    this.persist.remove('token');
    this.spinner.show();
    return this.http.post('/api/auth/login', {email, password});
  }

  public logout(): void {
    this.persist.remove('token');
    this.persist.remove('user');
    this.router.navigateByUrl('/account/login');
  }

  public getToken(): string {
     return this.persist.get('token');
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    return (token !== null && typeof token !== 'undefined');
  }
}
