import {Component, Input, OnInit} from '@angular/core';
import {NzModalRef, UploadFile} from 'ng-zorro-antd';
import {base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform} from 'ngx-image-cropper';

@Component({
    selector: 'app-modal-cropper',
    templateUrl: './modal-cropper.component.html',
    styleUrls: ['./modal-cropper.component.scss']
})
export class ModalCropperComponent implements OnInit {

    @Input() imageChangedEvent?: File;
    aspectRatio?: number;

    croppedImage: any = '';
    public croppedImageFile: any = null;
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    uploading = false;
    fileList: UploadFile[] = [];

    constructor(private modal: NzModalRef) {
    }

    destroyModal(): void {
        this.modal.destroy({data: 'this the result data'});
    }

    beforeUpload = (file: any): boolean => {
        this.imageChangedEvent = file;
        console.log(file);
        return false;
    };

    ngOnInit(): void {
    }


    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64
        this.croppedImageFile = this.dataURLtoFile(event.base64, 'image.png');
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        // console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        //console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    dataURLtoFile(dataurl, filename) {

        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }


    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }
}
