import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {environment} from '../../../environments/environment';
import {SpinnerService} from '../services/spinner.service';
import {tap} from 'rxjs/operators';
import {PersistService} from '../services/persist.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService, private spinner: SpinnerService, private persist: PersistService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.spinner.show();
        const httpRequest = new HttpRequest(<any> request.method, environment.server + request.url, request.body, {
            headers: request.headers,
            params: request.params,
            responseType: 'json'
        });

        request = Object.assign(request, httpRequest);
        if (this.auth.isAuthenticated()) {
            request = request.clone({
                setHeaders: {
                    Authorization: '' + this.auth.getToken()
                }
            });
        }
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.persist.set('token', event.headers.get('token'));
                this.onEnd();
            }
        }, (error: any) => {
            this.onEnd();
        }));
    }

    private onEnd(): void {
        this.hideLoader();
    }

    private showLoader(): void {
        this.spinner.show();
    }

    private hideLoader(): void {
        this.spinner.hide();
    }
}
