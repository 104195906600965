import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs-compat';
import {SpinnerService} from '../../services/spinner.service';
import {SpinnerState} from './loader';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  show = true;

  private subscription: Subscription;

  constructor(private spin: SpinnerService) {
  }

  ngOnInit() {
    this.subscription = this.spin.spinnerState.subscribe((state: SpinnerState) => {
     //this.show = state.show;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
