import { Injectable } from '@angular/core';
import {NzModalService} from "ng-zorro-antd";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private modal: NzModalService) { }

  success(message: string, callbackOk: any, callbackCancel: any) {
    this.modal.success({
      nzTitle: 'Exito',
      nzContent: message,
      nzOkText: 'Aceptar',
      nzOnOk: callbackOk
    });
  }
  error(message: string, callbackOk: any, callbackCancel: any) {
    this.modal.error({
      nzTitle: 'Lo sentimos',
      nzContent: message,
      nzOkText: 'Aceptar',
      nzOnOk: callbackOk
    });
  }

  question(message: string, callbackOk: any, callbackCancel: any) {
    this.modal.confirm({
      nzTitle: 'Pregunta',
      nzContent: message,
      nzOkText: 'Si',
      nzCancelText: 'No',
      nzOnOk: callbackOk,
      nzOnCancel: callbackCancel
    });
  }

}
