import {Injectable} from '@angular/core';
import {ModalCropperComponent} from './components/modal-cropper/modal-cropper.component';
import {NzModalService} from 'ng-zorro-antd';
import {AlertService} from "./alert.service";

@Injectable({
    providedIn: 'root'
})
export class ImageCropperService {

    constructor(private modal: NzModalService, private alert: AlertService) {

    }

    cropImage(aspectRatio): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            const modal = this.modal.create({
                nzTitle: 'Ajustar imagen',
                nzContent: ModalCropperComponent,
                nzWidth: '920px',
                nzMaskClosable: false,
                nzComponentParams: {
                    imageChangedEvent: null,
                    aspectRatio
                },
                nzFooter: [
                    {
                        label: 'Cancelar',
                        onClick: componentInstance => {
                            this.modal.closeAll();
                            resolve(null);
                        }
                    },
                    {
                        label: 'Cargar imagen',
                        type: 'primary',
                        onClick: componentInstance => {
                            if (componentInstance.croppedImageFile) {
                                this.modal.closeAll();
                                resolve(componentInstance.croppedImageFile);
                            } else {
                                this.alert.error('No ha seleccionado ninguna imagen.', null, null);
                            }
                        }
                    },

                ]
            });
        });

    }
}

