import {Injectable} from '@angular/core';
import {PersistService} from './persist.service';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    private dateInfo: any;

    constructor(private persistence: PersistService) {
        this.dateInfo = {
            months: [
                'enero',
                'febrero',
                'marzo',
                'abril',
                'mayo',
                'junio',
                'julio',
                'agosto',
                'septiembre',
                'octubre',
                'noviembre',
                'diciembre'
            ], monthsRe: [
                'en',
                'feb',
                'mar',
                'abr',
                'may',
                'jun',
                'jul',
                'ago',
                'sep',
                'oct',
                'nov',
                'dic'
            ],
            days: [
                'Domingo',
                'Lunes',
                'Martes',
                'Miércoles',
                'Jueves',
                'Viernes',
                'Sábado'
            ]
        };
    }

    getFormattedDate(milisecondsDate: number) {
        const tmpDate = new Date(milisecondsDate);
        let dd: string = tmpDate.getDate().toString();
        let mm: string = (tmpDate.getMonth() + 1).toString();
        let m: string = (tmpDate.getMinutes()).toString();
        let hrs: string = (tmpDate.getHours()).toString();


        const yyyy = tmpDate.getFullYear();

        if (Number(dd) < 10) {
            dd = '0' + dd;
        }
        if (Number(mm) < 10) {
            mm = '0' + mm;
        }

        if (Number(m) < 10) {
            m = '0' + m;
        }
        if (Number(hrs) < 10) {
            hrs = '0' + hrs;
        }

        return dd + '/' + mm + '/' + yyyy + ', ' + hrs + ':' + m + ' hrs';
    }

    getFormattedDateText(milisecondsDate: string) {
        const tmpDate = new Date(milisecondsDate);
        let dd: string = tmpDate.getDate().toString();
        let mm: string = (tmpDate.getMonth() + 1).toString();
        let m: string = (tmpDate.getMinutes()).toString();
        let hrs: string = (tmpDate.getHours()).toString();


        const yyyy = tmpDate.getFullYear();

        if (Number(dd) < 10) {
            dd = '0' + dd;
        }
        if (Number(mm) < 10) {
            mm = '0' + mm;
        }

        if (Number(m) < 10) {
            m = '0' + m;
        }
        if (Number(hrs) < 10) {
            hrs = '0' + hrs;
        }

        return dd + '/' + mm + '/' + yyyy + ', ' + hrs + ':' + m + ' hrs';
    }

    getSTATFormattedDateText(milisecondsDate: string) {
        const tmpDate = new Date(milisecondsDate);
        let dd: string = tmpDate.getDate().toString();
        let mm: string = (tmpDate.getMonth() + 1).toString();
        let m: string = (tmpDate.getMinutes()).toString();
        let hrs: string = (tmpDate.getHours()).toString();


        const yyyy = tmpDate.getFullYear();

        if (Number(dd) < 10) {
            dd = '0' + dd;
        }
        if (Number(mm) < 10) {
            mm = '0' + mm;
        }

        if (Number(m) < 10) {
            m = '0' + m;
        }
        if (Number(hrs) < 10) {
            hrs = '0' + hrs;
        }

        return dd + '. ' + this.dateInfo.monthsRe[Number(mm) - 1] + ' ' + yyyy;
    }

    getTextDate(millisecondsDate: any) {
        const tmpDate = new Date(Number(millisecondsDate));
        const day: string = this.dateInfo.days[tmpDate.getDay()];
        const date: string = tmpDate.getDate().toString();
        const month: string = this.dateInfo.months[tmpDate.getMonth()];
        const year: string = tmpDate.getFullYear().toString();

        return `${day}, ${date} de ${month} ${year}`;
    }

    getTextDateFromDate(dt: any) {
        const tmpDate = new Date(dt);
        const day: string = this.dateInfo.days[tmpDate.getDay()];
        const date: string = tmpDate.getDate().toString();
        const month: string = this.dateInfo.months[tmpDate.getMonth()];
        const year: string = tmpDate.getFullYear().toString();

        return `${day}, ${date} de ${month} ${year}`;
    }

    getFormattedTime(dt: any) {
        const tmpDate = new Date(dt);
        let m: string = (tmpDate.getMinutes()).toString();
        let hrs: string = (tmpDate.getHours()).toString();


        if (Number(m) < 10) {
            m = '0' + m;
        }
        if (Number(hrs) < 10) {
            hrs = '0' + hrs;
        }

        return hrs + ':' + m + ' hrs';
    }

    getImagesRepo(): string {
        return 'https://storage.googleapis.com/stat-storage-prod/images/';
    }

    getCurrentUser() {
        return this.persistence.get('user');
    }

    filterArray(searchTerm: string, array: any[], propertyName: string) {
        if (!searchTerm) return array;
        searchTerm = searchTerm.toLowerCase();
        return array.filter((obj: any) => {
            return String(obj[propertyName]).toLowerCase().includes(searchTerm);
        });
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

}
