import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

// @ts-ignore
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkAuth();
  }

  checkAuth(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    // this.router.navigateByUrl('/account/login');
    // alert('not logged');
    return false;
  }
}
