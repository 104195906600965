import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../../validators/must-match.validator';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

    @Input() optional?: boolean;

    public newPasswordForm: FormGroup;

    public submittedForm: boolean;

    constructor(private fb: FormBuilder) {
        this.newPasswordForm = this.fb.group({
            currentPassword: [null, [Validators.required]],
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]]
        }, {
            validator: MustMatch('password',
                'confirmPassword')
        });
    }

    ngOnInit() {
        this.submittedForm = false;
    }

    getForm() {
        return this.newPasswordForm.controls;
    }

    public validatePassword() {
        this.submittedForm = true;

    }
}
