import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {IconsProviderModule} from './icons-provider.module';
import {NgZorroAntdModule, NZ_I18N, es_ES} from 'ng-zorro-antd';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import es from '@angular/common/locales/es';
import {SpinnerService} from './utils/services/spinner.service';
import {SpinnerComponent} from './utils/others/spinner/spinner.component';
import {HttpWrapper} from './utils/services/http-wrapper.service';
import {UtilsService} from './utils/services/utils.service';
import {PersistService} from './utils/services/persist.service';
import {AuthService} from './utils/services/auth.service';
import {TokenInterceptor} from './utils/interceptors/token.interceptor';
import {PersistenceModule} from 'angular-persistence';
import {NgxSpinnerModule} from 'ngx-spinner';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {NzTableModule} from 'ng-zorro-antd/table';
import {ModalCropperComponent} from './utils/services/components/modal-cropper/modal-cropper.component';
import {ImageCropperService} from './utils/services/image-cropper.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import {NewPasswordComponent} from './utils/services/components/new-password/new-password.component';
import {NewEmailComponent} from "./utils/services/components/new-email/new-email.component";


registerLocaleData(es);

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        ModalCropperComponent,
        NewPasswordComponent,
        NewEmailComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        IconsProviderModule,
        NgZorroAntdModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        PersistenceModule,
        NgxSpinnerModule,
        NzModalModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        NzTableModule,
        ImageCropperModule,
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {provide: NZ_I18N, useValue: es_ES},
        SpinnerService,
        HttpWrapper,
        UtilsService,
        PersistService,
        AuthService,
        ImageCropperService
        //  ChatService
    ],
    bootstrap: [AppComponent],
    entryComponents: [ModalCropperComponent, NewPasswordComponent, NewEmailComponent]
})
export class AppModule {
}
