import {Component, OnInit} from '@angular/core';
import {SpinnerService} from './utils/services/spinner.service';
import {AuthService} from './utils/services/auth.service';
import {HttpWrapper} from './utils/services/http-wrapper.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    isCollapsed = false;

    constructor(private spinner: SpinnerService,
                private auth: AuthService,
                private http: HttpWrapper,
                private router: Router) {
        spinner.show();

    }

    ngOnInit(): void {

        if (this.auth.isAuthenticated()) {
            this.http.get('/api/user/refresh').toPromise().then((success) => {
              console.log(success);
            }).catch((error) => {
                this.auth.logout();
            });
        }

    }
}
