import {Injectable} from '@angular/core';
import { Subject } from 'rxjs-compat';
import { SpinnerState} from "../others/spinner/loader";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spinnerSubject = new Subject<SpinnerState>();

  spinnerState = this.spinnerSubject.asObservable();

  constructor() {}

  public show(): void {
    //alert('spinner showing');
    this.spinnerSubject.next(<SpinnerState>{show: true});
  }

  public hide(): void {
    this.spinnerSubject.next(<SpinnerState>{show: false});
  }
}
