import {Injectable} from '@angular/core';
import {PersistenceService, StorageType} from 'angular-persistence';

@Injectable({
  providedIn: 'root'
})
export class PersistService {
  private defaultStorageType = {type: StorageType.LOCAL};


  constructor(private persistenceService: PersistenceService) {
  }

  public set(name: string, data: any) {
    this.persistenceService.set(name, data, this.defaultStorageType);
  }

  public get(name: string) {
    return this.persistenceService.get(name, this.defaultStorageType.type);
  }

  public remove(name: string) {
    return this.persistenceService.remove(name, StorageType.LOCAL);
  }

  public clean() {
    this.persistenceService.clean(this.defaultStorageType.type);
  }


  public setTemp(name: string, data: any) {
    this.persistenceService.set(name, data, {type: StorageType.SESSION});
  }

  public getTemp(name: string) {
    return this.persistenceService.get(name, StorageType.SESSION);
  }

  public removeTemp(name: string) {
    return this.persistenceService.remove(name, StorageType.SESSION);
  }

  public cleanTemp() {
    this.persistenceService.clean(StorageType.SESSION);
  }
}
